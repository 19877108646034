// src/components/GameConfig/GameSpecificFields.js
import React from "react";
import MemoryMatchFields from "./MemoryMatchFields";
import JigsawPuzzleFields from "./JigsawPuzzleFields";

const GameSpecificFields = ({
  selectedGame,
  images,
  existingImages,
  onAddImages,
  onDeleteImage,
  backImage,
  existingBackImage,
  onAddBackImage,
  onDeleteBackImage,
  // Nuevos props para Screensaver Image
  screensaverImage,
  existingScreensaverImage,
  onAddScreensaverImage,
  onDeleteScreensaverImage,
  puzzleImage,
  existingPuzzleImage,
  onAddPuzzleImage,
  onDeletePuzzleImage,
  isLoading,
  customization,
  handleCustomizationChange,
  navbarLogo,
  existingNavbarLogo,
  handleAddNavbarLogo,
  handleDeleteNavbarLogo,
  profileLogoUrl,
}) => {
  switch (selectedGame) {
    case "MemoryMatch":
      return (
        <MemoryMatchFields
          images={images}
          existingImages={existingImages}
          onAddImages={onAddImages}
          onDeleteImage={onDeleteImage}
          backImage={backImage}
          existingBackImage={existingBackImage}
          onAddBackImage={onAddBackImage}
          onDeleteBackImage={onDeleteBackImage}
          // Pasar nuevos props para Screensaver Image
          screensaverImage={screensaverImage}
          existingScreensaverImage={existingScreensaverImage}
          onAddScreensaverImage={onAddScreensaverImage}
          onDeleteScreensaverImage={onDeleteScreensaverImage}
          isLoading={isLoading}
          customization={customization}
          handleCustomizationChange={handleCustomizationChange}
          navbarLogo={navbarLogo}
          existingNavbarLogo={existingNavbarLogo}
          handleAddNavbarLogo={handleAddNavbarLogo}
          handleDeleteNavbarLogo={handleDeleteNavbarLogo}
          profileLogoUrl={profileLogoUrl}
        />
      );
    case "JigsawPuzzle":
      return (
        <JigsawPuzzleFields
          puzzleImage={puzzleImage}
          existingPuzzleImage={existingPuzzleImage}
          onAddPuzzleImage={onAddPuzzleImage}
          onDeletePuzzleImage={onDeletePuzzleImage}
          isLoading={isLoading}
        />
      );
    default:
      return null;
  }
};

export default GameSpecificFields;
