import React from "react";
import "./PlayerInfo.css";

const PlayerInfo = ({ currentPlayer, timer, formatTime, textColor }) => {
  return (
    <div 
      className={`player-info ${currentPlayer ? 'has-player' : ''}`}
      style={{
        '--text-color': textColor || '#333',

      }}
    >
      <div className="player-header">
        <h2 className="player-title">
          Current Player
        </h2>
      </div>
      <div className="player-details">
        <div className="info-item">
          <div className="info-icon">👤</div>
          <div className="info-content">
            <div className="info-label">Player</div>
            <div className="info-value" title={currentPlayer}>
              {currentPlayer || "Waiting..."}
            </div>
          </div>
        </div>
        <div className="info-item">
          <div className="info-icon">⏱️</div>
          <div className="info-content">
            <div className="info-label">Time</div>
            <div className="info-value">{formatTime(timer)}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlayerInfo;