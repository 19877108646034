// src/components/games/MemoryMatchGame/MemoryMatchGame.js
import React, { useState, useEffect, useRef, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { db, auth } from "../../../firebaseConfig";
import { useAuthState } from "react-firebase-hooks/auth";
import { EmailAuthProvider, reauthenticateWithCredential } from "firebase/auth";
import {
  collection,
  addDoc,
  getDocs,
  getDoc,
  query,
  orderBy,
  deleteDoc,
  doc,
  limit,
} from "firebase/firestore";
import { usePreloadImages } from "../../../hooks/usePreloadImages";
import ResetPasswordModal from "../../modals/ResetPasswordModal";
import GameNavbar from "../../common/GameNavbar";
import Sidebar from "../../common/Sidebar";
import Podium from "../../common/Podium";
import LoadingSpinner from "../../LoadingSpinner";
import styles from "./MemoryMatchGame.module.css";
import { motion } from "framer-motion";

const MemoryMatchGame = () => {
  const { eventId, gameId } = useParams();
  const [user] = useAuthState(auth);
  const navigate = useNavigate();

  // Estados del juego y del jugador
  const [playerName, setPlayerName] = useState("");
  const [playerEmail, setPlayerEmail] = useState("");
  const [currentPlayer, setCurrentPlayer] = useState("");
  const [cards, setCards] = useState([]);
  const [flippedCards, setFlippedCards] = useState([]);
  const [matchedCards, setMatchedCards] = useState([]);
  const [timer, setTimer] = useState(0);
  const [images, setImages] = useState([]);
  const [backImage, setBackImage] = useState("");
  const [loading, setLoading] = useState(true);
  const [leaderboard, setLeaderboard] = useState([]);
  const [gameFinished, setGameFinished] = useState(false);

  const timerIntervalRef = useRef(null);

  // Variables para modales, verificación, etc.
  const [showResetModal, setShowResetModal] = useState(false);
  const [actionType, setActionType] = useState(null);
  const [showPodium, setShowPodium] = useState(false);
  const [topPlayers, setTopPlayers] = useState([]);
  const [isVerifyingPassword, setIsVerifyingPassword] = useState(false);

  // Customization
  const [customization, setCustomization] = useState({});
  const [navbarLogo, setNavbarLogo] = useState(null);

  // Preview countdown (para el tiempo que se muestran las tarjetas)
  const [previewCountdown, setPreviewCountdown] = useState(0);

  // NUEVO: Estados para el screensaver y timeout de inactividad
  const [screensaverActive, setScreensaverActive] = useState(false);
  const inactivityTimeoutRef = useRef(null);
  const cancelSessionTimeoutRef = useRef(null);

  // Formatear el tiempo
  const formatTime = (sec) => {
    const m = Math.floor(sec / 60);
    const s = sec % 60;
    return `${m}:${s < 10 ? "0" : ""}${s}`;
  };


  const preloadUrls = useMemo(() => {
    const urls = [];
    if (customization && Array.isArray(customization.images)) {
      urls.push(...customization.images);
    }
    if (customization && customization.backImageUrl) {
      urls.push(customization.backImageUrl);
    }
    if (customization && customization.screensaverImageUrl) {
      urls.push(customization.screensaverImageUrl);
    }
    return urls;
  }, [customization]);
  
  const imagesPreloaded = usePreloadImages(preloadUrls);
  




  // Cargar datos del juego
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!user) return;
        const docRef = doc(
          db,
          `users/${user.uid}/events/${eventId}/games/${gameId}`
        );
        const snap = await getDoc(docRef);
        if (snap.exists()) {
          const data = snap.data();
          setCustomization(data.customization || {});
          if (data.customization?.navbarLogoUrl) {
            setNavbarLogo(data.customization.navbarLogoUrl);
          }
          setImages(data.customization.images || []);
          setBackImage(data.customization.backImageUrl || "");
          // Leaderboard
          const scoresRef = collection(
            db,
            `users/${user.uid}/events/${eventId}/games/${gameId}/scores`
          );
          const scoresSnap = await getDocs(
            query(scoresRef, orderBy("time", "asc"))
          );
          const scoresArr = scoresSnap.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setLeaderboard(scoresArr);
        }
        setLoading(false);
      } catch (err) {
        console.error("Error fetching game data:", err);
        setLoading(false);
      }
    };
    fetchData();
  }, [eventId, gameId, user, navigate]);

  // Preparar cartas
  const prepareCards = () => {
    const duplicated = [...images, ...images];
    duplicated.sort(() => Math.random() - 0.5);
    const newCards = duplicated.map((img, i) => ({
      id: i,
      image: img,
      matched: false,
    }));
    setCards(newCards);
    setFlippedCards([]);
    setMatchedCards([]);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (images.length > 0) {
      prepareCards();
    }
  }, [images]);

  // Iniciar juego: reinicia cartas, timer y estado de juego
  const initializeGame = () => {
    prepareCards();
    setTimer(0);
    setGameFinished(false);
    const pTime = customization.memoryPreviewTime ?? 0;
    if (pTime > 0) {
      setPreviewCountdown(pTime);
      setFlippedCards([...Array(images.length * 2).keys()]);
    }
    // Reiniciar el timer de inactividad
    resetInactivityTimer();
  };

  // Timer: solo cuenta si hay jugador activo y no hay preview ni juego finalizado
  useEffect(() => {
    if (currentPlayer && previewCountdown === 0 && !gameFinished) {
      const interval = setInterval(() => {
        setTimer((prev) => prev + 1);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [currentPlayer, previewCountdown, gameFinished]);

  // Manejo del preview countdown
  useEffect(() => {
    if (previewCountdown > 0) {
      const intv = setInterval(() => {
        setPreviewCountdown((prev) => {
          if (prev <= 1) {
            clearInterval(intv);
            setFlippedCards([]);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
      return () => clearInterval(intv);
    }
  }, [previewCountdown]);

  // Función para reiniciar el timer de inactividad (para screensaver)
  const resetInactivityTimer = () => {
    if (inactivityTimeoutRef.current) clearTimeout(inactivityTimeoutRef.current);
    if (customization.inactivityTimeout > 0 && customization.screensaverImageUrl) {
      inactivityTimeoutRef.current = setTimeout(() => {
        setScreensaverActive(true);
        handleCancelSession();
      }, customization.inactivityTimeout * 1000);
    }
  };
  

  // Detectar actividad del usuario para reiniciar el timer de inactividad
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const events = ["mousemove", "mousedown", "keydown", "touchstart", "scroll"];
    const handleActivity = () => {
      if (screensaverActive) setScreensaverActive(false);
      resetInactivityTimer();
    };
    events.forEach((eventName) => window.addEventListener(eventName, handleActivity));
    resetInactivityTimer();
    return () => {
      events.forEach((eventName) => window.removeEventListener(eventName, handleActivity));
      if (inactivityTimeoutRef.current) clearTimeout(inactivityTimeoutRef.current);
      if (cancelSessionTimeoutRef.current) clearTimeout(cancelSessionTimeoutRef.current);
    };
  }, [customization.screensaverTimeout, customization.screensaverImageUrl, screensaverActive]);

  // Manejo de clic en cartas
  const handleCardClick = async (idx) => {
    if (!currentPlayer || gameFinished) return;
    if (previewCountdown > 0) return;
    if (flippedCards.length === 2) return;
    if (flippedCards.includes(idx)) return;
    if (cards[idx].matched) return;

    const newFlipped = [...flippedCards, idx];
    setFlippedCards(newFlipped);

    if (newFlipped.length === 2) {
      const [f, s] = newFlipped;
      if (cards[f].image === cards[s].image) {
        const newMatched = [...matchedCards, f, s];
        setMatchedCards(newMatched);
        setCards((old) =>
          old.map((c, i) => (i === f || i === s ? { ...c, matched: true } : c))
        );
        setFlippedCards([]);
        if (newMatched.length === cards.length) {
          await handleSaveScore();
          finishGame();
        }
      } else {
        setTimeout(() => {
          setFlippedCards([]);
        }, 1000);
      }
    }
  };

  const finishGame = () => {
    setGameFinished(true);
    setMatchedCards([]);
    setFlippedCards([]);
    // Limpiar los inputs de jugador al finalizar el juego
    setPlayerName("");
    setPlayerEmail("");
  };

  // Botón Start: inicia la sesión y el juego
  const handleStart = async () => {
    if (!playerName.trim()) {
      alert("Please enter your name");
      return;
    }
    // if (!playerEmail.trim()) {
    //   alert("Please enter your email");
    //   return;
    // }
    if (currentPlayer && !gameFinished) {
      await handleSaveScore();
    }
    setCurrentPlayer(playerName);
    initializeGame();
  };

  // Guardar el score y reordenar el leaderboard por mejor tiempo (ascendente)
  const handleSaveScore = async () => {
    if (!user || !currentPlayer) return;
    try {
      const scoresRef = collection(
        db,
        `users/${user.uid}/events/${eventId}/games/${gameId}/scores`
      );
      const ts = new Date();
      await addDoc(scoresRef, {
        name: currentPlayer,
        email: playerEmail,
        time: timer,
        timestamp: ts,
      });
      setLeaderboard((prev) => {
        const newScores = [
          ...prev,
          { name: currentPlayer, email: playerEmail, time: timer, timestamp: ts },
        ];
        newScores.sort((a, b) => a.time - b.time);
        return newScores;
      });
    } catch (err) {
      console.error("Error saving score:", err);
      alert("Error saving score. Please try again");
    }
  };

  const fetchTopPlayers = async () => {
    if (!user) return;
    try {
      const scoresRef = collection(
        db,
        `users/${user.uid}/events/${eventId}/games/${gameId}/scores`
      );
      const qSnap = await getDocs(query(scoresRef, orderBy("time", "asc"), limit(3)));
      const top = qSnap.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setTopPlayers(top);
      setShowPodium(true);
    } catch (err) {
      console.error("Error fetching top players:", err);
      alert("Error fetching top players.");
    }
  };

  // Menú y logo (usados en la navbar)
  const handleMenuClick = (action) => {
    if (action === "resetLeaderboard") {
      setActionType("resetLeaderboard");
      setShowResetModal(true);
    } else if (action === "finishGame") {
      setActionType("finishGame");
      setShowResetModal(true);
    } else if (action === "redirectToEvent") {
      setActionType("redirectToEvent");
      setShowResetModal(true);
    }
  };

  const handleLogoClick = () => {
    setActionType("redirectToEvent");
    setShowResetModal(true);
  };

  // Reset leaderboard: restablece la sesión
  const handleResetLeaderboard = async () => {
    setLoading(true);
    try {
      if (!user) return;
      const scoresRef = collection(
        db,
        `users/${user.uid}/events/${eventId}/games/${gameId}/scores`
      );
      const snap = await getDocs(scoresRef);
      for (const docu of snap.docs) {
        await deleteDoc(docu.ref);
      }
      setLeaderboard([]);
      setGameFinished(false);
      setCurrentPlayer("");
      setPlayerName("");
      setPlayerEmail("");
      setFlippedCards([]);
      setMatchedCards([]);
      setTimer(0);
      setPreviewCountdown(0);
    } catch (err) {
      console.error("Error resetting leaderboard:", err);
      alert("Error resetting leaderboard");
    } finally {
      setLoading(false);
      setShowResetModal(false);
    }
  };

  const handleFinishGame = async () => {
    setLoading(true);
    try {
      await fetchTopPlayers();
      await handleResetLeaderboard();
    } catch (err) {
      console.error("Error finishing game:", err);
    } finally {
      setLoading(false);
      setShowResetModal(false);
    }
  };

  const handleRestartGame = () => {
    setShowPodium(false);
    setGameFinished(false);
    setCurrentPlayer("");
    setPlayerName("");
    setPlayerEmail("");
    setTimer(0);
    setMatchedCards([]);
    setFlippedCards([]);
    setPreviewCountdown(0);
  };

  // Botón Cancel Session (ahora se invoca desde el Sidebar)
  const handleCancelSession = () => {
    setCurrentPlayer("");
    setTimer(0);
    setFlippedCards([]);
    setMatchedCards([]);
    setPlayerName("");
    setPlayerEmail("");
    if (inactivityTimeoutRef.current) clearTimeout(inactivityTimeoutRef.current);
    if (cancelSessionTimeoutRef.current) clearTimeout(cancelSessionTimeoutRef.current);
  };

  const handleVerifyPassword = async (password) => {
    setIsVerifyingPassword(true);
    try {
      const cred = EmailAuthProvider.credential(user.email, password);
      await reauthenticateWithCredential(user, cred);
      if (actionType === "resetLeaderboard") {
        await handleResetLeaderboard();
      } else if (actionType === "finishGame") {
        await handleFinishGame();
      } else if (actionType === "redirectToEvent") {
        navigate(`/event/${eventId}`);
      }
      setShowResetModal(false);
    } catch (err) {
      console.error("Error verifying password:", err);
      throw err;
    } finally {
      setIsVerifyingPassword(false);
    }
  };

  // Estilos para el juego
  const gameStyles = {
    "--sidebar-bg": customization.sidebarColor || "#9036d5",
    "--sidebar-text-color": customization.sidebarTextColor || "#ffffff",
    "--navbar-bg": customization.navbarColor || "#5d3dfd",
    "--navbar-title-text-color": customization.navbarTitleTextColor || "#ffffff",
    "--gameboard-background-color": customization.gameboardBackgroundColor || "#ffffff",
    "--card-border-color": customization.cardBorderColor || "#000000",
  };

  if (loading) {
    return <LoadingSpinner message="Processing..." />;
  }
  
 
  

if (!imagesPreloaded) {
  return <LoadingSpinner message="Loading game resources..." />;
}

  return (
    <div className={styles.memoryMatchGame} style={gameStyles}>
      <GameNavbar
        customization={customization}
        onMenuClick={handleMenuClick}
        navbarLogo={navbarLogo}
        onLogoClick={handleLogoClick}
      />

      <div className={styles.gameLayout}>
        <Sidebar
          currentPlayer={currentPlayer}
          timer={timer}
          leaderboard={leaderboard}
          formatTime={formatTime}
          customization={{
            sidebarColor: customization.sidebarColor || "#9036d5",
            sidebarTextColor: customization.sidebarTextColor || "#ffffff",
          }}
          onCancelSession={handleCancelSession}
        />
        <div
          className={styles.gameBoardContainer}
          style={{
            backgroundColor: customization.gameboardBackgroundColor || "#ffffff",
          }}
        >
          {previewCountdown > 0 && (
            <div className={styles.previewOverlay}>
              <span className={styles.previewCountdown}>
                {previewCountdown}
              </span>
            </div>
          )}

          <div className={styles.gameBoard}>
            {cards.map((card, i) => {
              const isFlipped =
                flippedCards.includes(i) || matchedCards.includes(i);
              return (
                <motion.div
                  key={i}
                  className={styles.card}
                  onClick={() => handleCardClick(i)}
                  whileHover={{ scale: 1.05 }}
                >
                  <motion.div
                    className={`${styles.cardInner} ${
                      isFlipped ? styles.flipped : ""
                    }`}
                    animate={{ rotateY: isFlipped ? 180 : 0 }}
                    transition={{ duration: 0.3, ease: "easeInOut" }}
                  >
                    <div
                      className={styles.cardFront}
                      style={{ backgroundImage: `url(${card.image})` }}
                    ></div>
                    <div
                      className={styles.cardBack}
                      style={{
                        backgroundImage: backImage
                          ? `url(${backImage})`
                          : "none",
                        backgroundColor: backImage ? "transparent" : "#9036d5",
                      }}
                    ></div>
                  </motion.div>
                </motion.div>
              );
            })}
          </div>

          {!currentPlayer && !gameFinished && (
            <div className={styles.startContainer}>
              <input
                type="text"
                className={styles.playerInput}
                placeholder="Enter your name"
                value={playerName}
                onChange={(e) => setPlayerName(e.target.value)}
              />
              {/* <input
                type="email"
                className={styles.playerInput}
                placeholder="Enter your email"
                value={playerEmail}
                onChange={(e) => setPlayerEmail(e.target.value)}
              /> */}
              <button className={styles.startButton} onClick={handleStart}>
                Start Game
              </button>
            </div>
          )}

          {gameFinished && (
            <div className={styles.gameFinished}>
              <h2>Congratulations!</h2>
              <p>Your Time: {formatTime(timer)}</p>
              <input
                type="text"
                value={playerName}
                onChange={(e) => setPlayerName(e.target.value)}
                placeholder="Enter the next player's name"
                className={styles.playerInput}
                disabled={loading}
              />
              {/* <input
                type="email"
                value={playerEmail}
                onChange={(e) => setPlayerEmail(e.target.value)}
                placeholder="Enter the next player's email"
                className={styles.playerInput}
                disabled={loading}
              /> */}
              <button
                onClick={handleStart}
                className={styles.startButton}
                disabled={loading}
              >
                Start New Game
              </button>
            </div>
          )}
        </div>
      </div>

      {showPodium && (
        <Podium
          topPlayers={topPlayers}
          onRestart={handleRestartGame}
          formatTime={formatTime}
        />
      )}

      {showResetModal && (
        <ResetPasswordModal
          onSubmit={handleVerifyPassword}
          onCancel={() => setShowResetModal(false)}
          isLoading={isVerifyingPassword}
          actionName={
            actionType === "resetLeaderboard"
              ? "Reset Leaderboard"
              : actionType === "finishGame"
              ? "Finalize Game"
              : actionType === "redirectToEvent"
              ? "Return to Event"
              : ""
          }
        />
      )}

      {/* Overlay del Screensaver */}
      {screensaverActive && customization.screensaverImageUrl && (
        <div
          className={styles.screensaverOverlay}
          onClick={() => {
            setScreensaverActive(false);
            resetInactivityTimer();
          }}
          style={{ backgroundImage: `url(${customization.screensaverImageUrl})` }}
        ></div>
      )}
    </div>
  );
};

export default MemoryMatchGame;
