// src/components/GameConfig/GameForm.js
import React from 'react';
import './GameForm.css';

const GameForm = ({
  gameName,
  setGameName,
  selectedGame,
  setSelectedGame,
  isEditMode,
  isLoading,
}) => {
  return (
    <div className="game-form-container">
      <div className="form-group">
        <label htmlFor="gameName">Game Name</label>
        <input
          type="text"
          id="gameName"
          value={gameName}
          onChange={(e) => setGameName(e.target.value)}
          disabled={isLoading}
          placeholder="Enter game name"
          className="form-input"
        />
      </div>
      
      <div className="form-group">
        <label htmlFor="gameType">Game Type</label>
        {isEditMode ? (
          <input
            type="text"
            id="gameType"
            value={
              selectedGame === 'MemoryMatch'
                ? 'Memory Match'
                : selectedGame === 'JigsawPuzzle'
                ? 'Jigsaw Puzzle'
                : ''
            }
            readOnly
            className="form-input"
          />
        ) : (
          <select
            id="gameType"
            value={selectedGame}
            onChange={(e) => setSelectedGame(e.target.value)}
            disabled={isLoading}
            className="form-select"
          >
            <option value="">Select a game type</option>
            <option value="MemoryMatch">Memory Match</option>
            <option value="JigsawPuzzle">Jigsaw Puzzle</option>
          </select>
        )}
      </div>
    </div>
  );
};

export default GameForm;