// src/components/GameConfig/GameConfig.js
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { FaClock,FaImage } from "react-icons/fa";
import { storage, auth, db } from "../../firebaseConfig";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { doc, getDoc, updateDoc, collection, addDoc } from "firebase/firestore";
import { FaSave, FaTrash, FaUpload, FaPalette } from "react-icons/fa";
import { GiCardPlay } from "react-icons/gi";
import { MdPreview } from "react-icons/md";
import "./GameConfig.css";
import LoadingSpinner from "../LoadingSpinner";
import { toast } from "react-toastify";
import GameConfigHeader from "./GameConfigHeader";
import GameForm from "./GameForm";
import GameSpecificFields from "./GameSpecificFields";
import CropperModal from "./CropperModal";
import GameNavbar from "../common/GameNavbar";
import Sidebar from "../common/Sidebar";

const GameConfig = () => {
  const { eventId, gameId } = useParams();
  const navigate = useNavigate();

  // Principales
  const [gameName, setGameName] = useState("");
  const [selectedGame, setSelectedGame] = useState("");
  const [isEditMode, setIsEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("content");

  // Customization
  const [customization, setCustomization] = useState({
    navbarColor: "#6C5CE7",
    navbarTitle: "",
    navbarTitleTextColor: "#ffffff",
    logoOption: "noLogo",
    sidebarColor: "#A29BFE",
    sidebarTextColor: "#ffffff",
    cardBorderColor: "#2D3436",
    gameboardBackgroundColor: "#F5F6FA",
    memoryPreviewTime: 0,
    inactivityTimeout: 30,
  });

  // Memory
  const [images, setImages] = useState([]);
  const [existingImages, setExistingImages] = useState([]);
  const [backImage, setBackImage] = useState(null);
  const [existingBackImage, setExistingBackImage] = useState(null);

  // Screensaver Image
  const [screensaverImage, setScreensaverImage] = useState(null);
  const [existingScreensaverImage, setExistingScreensaverImage] = useState(null);

  // Puzzle
  const [puzzleImage, setPuzzleImage] = useState(null);
  const [existingPuzzleImage, setExistingPuzzleImage] = useState(null);

  // Navbar
  const [navbarLogo, setNavbarLogo] = useState(null);
  const [existingNavbarLogo, setExistingNavbarLogo] = useState(null);

  // Profile
  const [profileLogoUrl, setProfileLogoUrl] = useState("");

  // Cropper
  const [cropperModalOpen, setCropperModalOpen] = useState(false);
  const [currentImageFile, setCurrentImageFile] = useState(null);
  const [currentImageType, setCurrentImageType] = useState("");
  const aspectRatioValue = currentImageType === "screensaverImage" ? 16 / 9 : 1;

  // Borrar del Storage
  const [imagesToDelete, setImagesToDelete] = useState([]);
  const [pendingFiles, setPendingFiles] = useState([]);

  // ========================================
  // Cargar data si Modo Edit
  // ========================================
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const user = auth.currentUser;
      if (!user) {
        setIsLoading(false);
        return;
      }
      if (gameId) {
        try {
          const gameRef = doc(
            db,
            `users/${user.uid}/events/${eventId}/games/${gameId}`
          );
          const snap = await getDoc(gameRef);
          if (snap.exists()) {
            const data = snap.data();
            setGameName(data.gameName || "");
            setSelectedGame(data.gameType || "");
            setCustomization({
              navbarColor: data.customization?.navbarColor || "#6C5CE7",
              navbarTitle: data.customization?.navbarTitle || "",
              navbarTitleTextColor:
                data.customization?.navbarTitleTextColor || "#ffffff",
              logoOption: data.customization?.logoOption || "noLogo",
              sidebarColor: data.customization?.sidebarColor || "#A29BFE",
              sidebarTextColor:
                data.customization?.sidebarTextColor || "#ffffff",
              cardBorderColor: data.customization?.cardBorderColor || "#2D3436",
              gameboardBackgroundColor:
                data.customization?.gameboardBackgroundColor || "#F5F6FA",
              memoryPreviewTime: data.customization?.memoryPreviewTime || 0,
              inactivityTimeout: data.customization?.inactivityTimeout || 30,
            });
            if (data.gameType === "MemoryMatch") {
              setExistingImages(data.customization.images || []);
              setExistingBackImage(data.customization.backImageUrl || null);
              setExistingScreensaverImage(
                data.customization.screensaverImageUrl || null
              );
            } else if (data.gameType === "JigsawPuzzle") {
              setExistingPuzzleImage(data.customization?.puzzleImageUrl || null);
            }
            if (data.customization.navbarLogoUrl) {
              setExistingNavbarLogo(data.customization.navbarLogoUrl);
            } else {
              setExistingNavbarLogo(null);
            }
            setIsEditMode(true);
            const userProfileLogoUrl = await getUserProfileLogoUrl(user.uid);
            setProfileLogoUrl(userProfileLogoUrl);
          }
        } catch (err) {
          console.error("Error fetching game data:", err);
        }
      } else {
        setIsEditMode(false);
        const userProfileLogoUrl = await getUserProfileLogoUrl(user.uid);
        setProfileLogoUrl(userProfileLogoUrl);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [eventId, gameId]);

  // ========================================
  // Manejo de cambios
  // ========================================
  const handleCustomizationChange = (e) => {
    const { name, value } = e.target;
    setCustomization((prev) => ({ ...prev, [name]: value }));
  };

  // ========================================
  // Manejo de imágenes con Cropper
  // ========================================
  const handleAddImages = (e) => {
    const files = Array.from(e.target.files);
    if (!files.length) return;
    setPendingFiles(files);
    processNextImage([...files]);
  };

  const processNextImage = (files) => {
    const f = files.shift();
    if (f) {
      setCurrentImageFile(f);
      setCurrentImageType("memoryImage");
      setCropperModalOpen(true);
      setPendingFiles(files);
    }
  };

  const handleAddBackImage = (e) => {
    const file = e.target.files[0];
    if (file) {
      setCurrentImageFile(file);
      setCurrentImageType("backImage");
      setCropperModalOpen(true);
    }
  };

  const handleAddScreensaverImage = (e) => {
    const file = e.target.files[0];
    if (file) {
      setCurrentImageFile(file);
      setCurrentImageType("screensaverImage");
      setCropperModalOpen(true);
    }
  };

  const handleAddPuzzleImage = (e) => {
    const file = e.target.files[0];
    if (file) {
      setCurrentImageFile(file);
      setCurrentImageType("puzzleImage");
      setCropperModalOpen(true);
    }
  };

  const handleAddNavbarLogo = (e) => {
    const file = e.target.files[0];
    if (file) {
      setCurrentImageFile(file);
      setCurrentImageType("navbarLogo");
      setCropperModalOpen(true);
    }
  };

  const handleCrop = async (croppedBlob) => {
    if (!croppedBlob) {
      toast.error("No se obtuvo imagen recortada.");
      closeCropper();
      return;
    }
    try {
      const compressedFile = new File([croppedBlob], currentImageFile.name, {
        type: croppedBlob.type,
        lastModified: Date.now(),
      });
      const previewUrl = URL.createObjectURL(croppedBlob);
      switch (currentImageType) {
        case "memoryImage":
          setImages((prev) => [
            ...prev,
            { file: compressedFile, url: previewUrl },
          ]);
          if (pendingFiles.length > 0) {
            processNextImage([...pendingFiles]);
          } else {
            closeCropper();
          }
          break;
        case "backImage":
          setBackImage(compressedFile);
          closeCropper();
          break;
        case "screensaverImage":
          setScreensaverImage(compressedFile);
          closeCropper();
          break;
        case "puzzleImage":
          setPuzzleImage(compressedFile);
          closeCropper();
          break;
        case "navbarLogo":
          setNavbarLogo(compressedFile);
          closeCropper();
          break;
        default:
          closeCropper();
          break;
      }
    } catch (err) {
      console.error("Error en handleCrop =>", err);
      toast.error("Error procesando la imagen");
      closeCropper();
    }
  };

  const closeCropper = () => {
    setCropperModalOpen(false);
    setCurrentImageFile(null);
    setCurrentImageType("");
  };

  // ========================================
  // Borrar imágenes
  // ========================================
  const handleDeleteImage = (index, type) => {
    if (type === "new") {
      const updatedImages = [...images];
      updatedImages.splice(index, 1);
      setImages(updatedImages);
    } else if (type === "existing") {
      const url = existingImages[index];
      setImagesToDelete((prev) => [...prev, url]);
      const updatedExistingImages = [...existingImages];
      updatedExistingImages.splice(index, 1);
      setExistingImages(updatedExistingImages);
    }
  };

  const handleDeleteBackImage = () => {
    if (existingBackImage) {
      setImagesToDelete((prev) => [...prev, existingBackImage]);
    }
    setBackImage(null);
    setExistingBackImage(null);
  };

  const handleDeleteScreensaverImage = () => {
    if (existingScreensaverImage) {
      setImagesToDelete((prev) => [...prev, existingScreensaverImage]);
    }
    setScreensaverImage(null);
    setExistingScreensaverImage(null);
  };

  const handleDeletePuzzleImage = () => {
    if (existingPuzzleImage) {
      setImagesToDelete((prev) => [...prev, existingPuzzleImage]);
    }
    setPuzzleImage(null);
    setExistingPuzzleImage(null);
  };

  const handleDeleteNavbarLogo = () => {
    if (existingNavbarLogo) {
      setImagesToDelete((prev) => [...prev, existingNavbarLogo]);
    }
    setNavbarLogo(null);
    setExistingNavbarLogo(null);
  };

  // ========================================
  // Guardar configuración
  // ========================================
  const handleSaveConfig = async () => {
    setIsLoading(true);
    const user = auth.currentUser;
    if (!user) {
      setIsLoading(false);
      return;
    }
    if (!gameName.trim()) {
      toast.error("Game name cannot be empty.");
      setIsLoading(false);
      return;
    }
    if (!selectedGame) {
      toast.error("Please select a game type.");
      setIsLoading(false);
      return;
    }
    let finalGameId = gameId;
    try {
      let gameDocRef;
      if (isEditMode) {
        gameDocRef = doc(
          db,
          `users/${user.uid}/events/${eventId}/games/${gameId}`
        );
      } else {
        const docRef = await addDoc(
          collection(db, `users/${user.uid}/events/${eventId}/games`),
          {
            gameName,
            gameType: selectedGame,
            customization: {},
          }
        );
        finalGameId = docRef.id;
        gameDocRef = doc(
          db,
          `users/${user.uid}/events/${eventId}/games/${finalGameId}`
        );
      }
      const storagePath = `users/${user.uid}/events/${eventId}/games/${finalGameId}`;
      
      const uploadImage = async (file, folder, prefix = "") => {
        const imageRef = ref(
          storage,
          `${storagePath}/${folder}/${prefix}_${Date.now()}_${file.name}`
        );
        await uploadBytes(imageRef, file);
        return await getDownloadURL(imageRef);
      };

      let customizationData = { ...customization };

      if (selectedGame === "MemoryMatch") {
        const totalImages = images.length + existingImages.length;
       // if (totalImages !== 12) {
          //toast.error("You must upload exactly 12 images for the cards.");
         // setIsLoading(false);
         // return;
        //}
        const newMemoryUrls = await Promise.all(
          images.map(async (obj) => {
            const file = obj.file;
            console.log("Uploading memory =>", file.name);
            return await uploadImage(file, "images", "memory");
          })
        );
        customizationData.images = [
          ...existingImages,
          ...newMemoryUrls.filter(Boolean),
        ];
        if (backImage) {
          console.log("Uploading backImage =>", backImage.name);
          customizationData.backImageUrl = await uploadImage(
            backImage,
            "backImage",
            "backImage"
          );
        } else if (existingBackImage) {
          customizationData.backImageUrl = existingBackImage;
        } else {
          customizationData.backImageUrl = null;
        }
        if (screensaverImage) {
          try {
            if (existingScreensaverImage) {
              const pathToDel = getStoragePathFromUrl(existingScreensaverImage);
              if (pathToDel) {
                console.log("Deleting old screensaver image from storage =>", pathToDel);
                await deleteObject(ref(storage, pathToDel));
              }
            }
          } catch (err) {
            console.error("Error deleting old screensaver image:", err);
          }
          console.log("Uploading screensaverImage =>", screensaverImage.name);
          customizationData.screensaverImageUrl = await uploadImage(
            screensaverImage,
            "screensaverimage",
            "screensaver"
          );
        } else if (existingScreensaverImage) {
          customizationData.screensaverImageUrl = existingScreensaverImage;
        } else {
          customizationData.screensaverImageUrl = null;
        }
      } else if (selectedGame === "JigsawPuzzle") {
        if (puzzleImage) {
          console.log("Uploading puzzle =>", puzzleImage.name);
          const puzzleUrl = await uploadImage(
            puzzleImage,
            "puzzleImage",
            "puzzleImage"
          );
          customizationData.puzzleImageUrl = puzzleUrl;
        } else if (existingPuzzleImage) {
          customizationData.puzzleImageUrl = existingPuzzleImage;
        } else {
          customizationData.puzzleImageUrl = null;
        }
      }

      if (customization.logoOption === "uploadLogo") {
        if (navbarLogo) {
          console.log("Uploading navbarLogo =>", navbarLogo.name);
          const navUrl = await uploadImage(navbarLogo, "navbarLogo", "navbarLogo");
          customizationData.navbarLogoUrl = navUrl;
        } else if (existingNavbarLogo) {
          customizationData.navbarLogoUrl = existingNavbarLogo;
        } else {
          customizationData.navbarLogoUrl = "";
        }
      } else if (customization.logoOption === "profileLogo") {
        customizationData.navbarLogoUrl = profileLogoUrl;
      } else {
        customizationData.navbarLogoUrl = "";
      }

      if (imagesToDelete.length > 0) {
        console.log("Deleting =>", imagesToDelete);
        for (let url of imagesToDelete) {
          const pathToDel = getStoragePathFromUrl(url);
          if (pathToDel) {
            console.log("Deleting from storage =>", pathToDel);
            const delRef = ref(storage, pathToDel);
            await deleteObject(delRef);
          }
        }
        setImagesToDelete([]);
      }

      console.log("Updating doc =>", customizationData);
      await updateDoc(gameDocRef, {
        gameName,
        gameType: selectedGame,
        customization: customizationData,
      });
      console.log("Saved config successfully!");
      navigate(`/event/${eventId}`);
    } catch (err) {
      console.error("Error saving config =>", err);
      toast.error("Failed to save the game configuration.");
      setIsLoading(false);
      return;
    } finally {
      setIsLoading(false);
    }
  };

  const getStoragePathFromUrl = (url) => {
    try {
      const decodedUrl = decodeURIComponent(url);
      const regex = /\/o\/(.*?)\?alt=media/;
      const matches = decodedUrl.match(regex);
      if (matches && matches[1]) {
        return matches[1];
      }
    } catch (err) {
      console.error("getStoragePathFromUrl =>", err);
    }
    return null;
  };

  const gameStyles = {
    "--sidebar-bg": customization.sidebarColor || "#A29BFE",
    "--sidebar-text-color": customization.sidebarTextColor || "#ffffff",
    "--navbar-bg": customization.navbarColor || "#6C5CE7",
    "--navbar-title-text-color": customization.navbarTitleTextColor || "#ffffff",
    "--gameboard-background-color": customization.gameboardBackgroundColor || "#F5F6FA",
    "--card-border-color": customization.cardBorderColor || "#2D3436",
  };

  if (isLoading) {
    return <LoadingSpinner message="Processing..." />;
  }

  return (
    <div className="game-config-container" style={gameStyles}>
      <header className="config-header">
        <h1>{isEditMode ? "Edit Game Configuration" : "Create New Game"}</h1>
        <p>Customize every aspect of your memory game</p>
      </header>

      <div className="config-layout">
        <nav className="config-nav">
          <button 
            className={`nav-btn ${activeTab === "content" ? "active" : ""}`}
            onClick={() => setActiveTab("content")}
          >
            <GiCardPlay /> Game Content
          </button>
          <button 
            className={`nav-btn ${activeTab === "design" ? "active" : ""}`}
            onClick={() => setActiveTab("design")}
          >
            <FaPalette /> Design
          </button>
          <button 
            className={`nav-btn ${activeTab === "settings" ? "active" : ""}`}
            onClick={() => setActiveTab("settings")}
          >
            <FaClock /> Settings
          </button>
        </nav>

        <main className="config-main">
          <section className="game-name-section">
            <label htmlFor="gameName">Game Title</label>
            <input
              id="gameName"
              type="text"
              value={gameName}
              onChange={(e) => setGameName(e.target.value)}
              placeholder="Enter game title..."
              disabled={isLoading}
            />
          </section>

          <div className="tab-content">
            {activeTab === "content" && (
              <>
                <GameForm
                  gameName={gameName}
                  setGameName={setGameName}
                  selectedGame={selectedGame}
                  setSelectedGame={setSelectedGame}
                  isEditMode={isEditMode}
                  isLoading={isLoading}
                />

                {selectedGame === "MemoryMatch" && (
                  <GameSpecificFields
                    selectedGame={selectedGame}
                    images={images}
                    existingImages={existingImages}
                    onAddImages={handleAddImages}
                    onDeleteImage={handleDeleteImage}
                    backImage={backImage}
                    existingBackImage={existingBackImage}
                    onAddBackImage={handleAddBackImage}
                    onDeleteBackImage={handleDeleteBackImage}
                    screensaverImage={screensaverImage}
                    existingScreensaverImage={existingScreensaverImage}
                    onAddScreensaverImage={handleAddScreensaverImage}
                    onDeleteScreensaverImage={handleDeleteScreensaverImage}
                    isLoading={isLoading}
                  />
                )}

                {selectedGame === "JigsawPuzzle" && (
                  <GameSpecificFields
                    selectedGame={selectedGame}
                    puzzleImage={puzzleImage}
                    existingPuzzleImage={existingPuzzleImage}
                    onAddPuzzleImage={handleAddPuzzleImage}
                    onDeletePuzzleImage={handleDeletePuzzleImage}
                    isLoading={isLoading}
                  />
                )}
              </>
            )}

            {activeTab === "design" && (
              <div className="design-tab">
                <div className="logo-customization">
                  <h2><FaImage /> Logo & Title</h2>
                  <div className="form-group">
                    <label>Navbar Title:</label>
                    <input
                      type="text"
                      name="navbarTitle"
                      value={customization.navbarTitle || ""}
                      onChange={handleCustomizationChange}
                      disabled={isLoading}
                      placeholder="Enter Game Title"
                    />
                  </div>
                  
                  <div className="form-group">
                    <label>Navbar Logo:</label>
                    <div className="logo-options">
                      <label className="radio-label">
                        <input
                          type="radio"
                          name="logoOption"
                          value="profileLogo"
                          checked={customization.logoOption === "profileLogo"}
                          onChange={handleCustomizationChange}
                          disabled={isLoading}
                        />
                        Use Profile Logo
                      </label>
                      <label className="radio-label">
                        <input
                          type="radio"
                          name="logoOption"
                          value="uploadLogo"
                          checked={customization.logoOption === "uploadLogo"}
                          onChange={handleCustomizationChange}
                          disabled={isLoading}
                        />
                        Upload New Logo
                      </label>
                      <label className="radio-label">
                        <input
                          type="radio"
                          name="logoOption"
                          value="noLogo"
                          checked={customization.logoOption === "noLogo"}
                          onChange={handleCustomizationChange}
                          disabled={isLoading}
                        />
                        No Logo
                      </label>
                    </div>
                    {customization.logoOption === "uploadLogo" && (
                      <div className="logo-upload">
                        <input
                          type="file"
                          name="navbarLogo"
                          accept="image/*"
                          onChange={handleAddNavbarLogo}
                          disabled={isLoading}
                          id="navbarLogoUpload"
                        />
                        <label htmlFor="navbarLogoUpload" className="upload-btn">
                          <FaUpload /> Select Logo
                        </label>
                        {(navbarLogo || existingNavbarLogo) && (
                          <div className="logo-preview">
                            <img
                              src={navbarLogo ? URL.createObjectURL(navbarLogo) : existingNavbarLogo}
                              alt="Logo preview"
                            />
                            <button
                              className="delete-btn"
                              onClick={handleDeleteNavbarLogo}
                              disabled={isLoading}
                            >
                              <FaTrash />
                            </button>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>

                <div className="color-customization">
                  <h2><FaPalette /> Color Scheme</h2>
                  <div className="color-grid">
                    <div className="color-option">
                      <label>Navbar Color</label>
                      <div className="color-picker">
                        <input
                          type="color"
                          name="navbarColor"
                          value={customization.navbarColor || "#6C5CE7"}
                          onChange={handleCustomizationChange}
                          disabled={isLoading}
                        />
                        <span>{customization.navbarColor}</span>
                      </div>
                    </div>

                    <div className="color-option">
                      <label>Navbar Text Color</label>
                      <div className="color-picker">
                        <input
                          type="color"
                          name="navbarTitleTextColor"
                          value={customization.navbarTitleTextColor || "#ffffff"}
                          onChange={handleCustomizationChange}
                          disabled={isLoading}
                        />
                        <span>{customization.navbarTitleTextColor}</span>
                      </div>
                    </div>

                    <div className="color-option">
                      <label>Sidebar Color</label>
                      <div className="color-picker">
                        <input
                          type="color"
                          name="sidebarColor"
                          value={customization.sidebarColor || "#A29BFE"}
                          onChange={handleCustomizationChange}
                          disabled={isLoading}
                        />
                        <span>{customization.sidebarColor}</span>
                      </div>
                    </div>

                    <div className="color-option">
                      <label>Sidebar Text Color</label>
                      <div className="color-picker">
                        <input
                          type="color"
                          name="sidebarTextColor"
                          value={customization.sidebarTextColor || "#ffffff"}
                          onChange={handleCustomizationChange}
                          disabled={isLoading}
                        />
                        <span>{customization.sidebarTextColor}</span>
                      </div>
                    </div>

                    {selectedGame === "MemoryMatch" && (
                      <>
                        <div className="color-option">
                          <label>Gameboard Background</label>
                          <div className="color-picker">
                            <input
                              type="color"
                              name="gameboardBackgroundColor"
                              value={customization.gameboardBackgroundColor || "#F5F6FA"}
                              onChange={handleCustomizationChange}
                              disabled={isLoading}
                            />
                            <span>{customization.gameboardBackgroundColor}</span>
                          </div>
                        </div>

                        <div className="color-option">
                          <label>Card Border Color</label>
                          <div className="color-picker">
                            <input
                              type="color"
                              name="cardBorderColor"
                              value={customization.cardBorderColor || "#2D3436"}
                              onChange={handleCustomizationChange}
                              disabled={isLoading}
                            />
                            <span>{customization.cardBorderColor}</span>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>

                <div className="live-preview-section">
                  <h2>Live Preview</h2>
                  <div className="preview-container">
                    <GameNavbar
                      customization={customization}
                      onMenuClick={() => {}}
                      navbarLogo={
                        customization.logoOption === "uploadLogo" && navbarLogo
                          ? URL.createObjectURL(navbarLogo)
                          : customization.logoOption === "profileLogo"
                          ? profileLogoUrl
                          : null
                      }
                    />
                    <div className="game-layout">
                      <Sidebar
                        customization={customization}
                        currentPlayer="Player Name"
                        timer={0}
                        leaderboard={[]}
                        formatTime={() => "00:00"}
                      />
                      <div
                        className="gameboard-preview"
                        style={{
                          backgroundColor: customization.gameboardBackgroundColor || "#F5F6FA",
                        }}
                      >
                        {selectedGame === "MemoryMatch" && (
                          <div className="cards-preview">
                            {[...Array(6)].map((_, idx) => (
                              <div
                                key={idx}
                                className="card-preview"
                                style={{
                                  borderColor: customization.cardBorderColor || "#2D3436",
                                }}
                              ></div>
                            ))}
                          </div>
                        )}
                        {selectedGame === "JigsawPuzzle" && (
                          <div className="puzzle-preview"></div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {activeTab === "settings" && (
              <div className="settings-tab">
                <div className="time-settings">
                  <h2><FaClock /> Timing Settings</h2>
                  
                  {selectedGame === "MemoryMatch" && (
                    <div className="setting-option">
                      <label>
                        <span>Card Preview Time (seconds)</span>
                        <input
                          type="number"
                          name="memoryPreviewTime"
                          value={customization.memoryPreviewTime || 0}
                          onChange={handleCustomizationChange}
                          disabled={isLoading}
                          min="0"
                        />
                      </label>
                      <p className="setting-description">
                        The cards remain face-up for this duration at the start of the game.
                      </p>
                    </div>
                  )}

                  <div className="setting-option">
                    <label>
                      <span>Inactivity Timeout (seconds)</span>
                      <input
                        type="number"
                        name="inactivityTimeout"
                        value={customization.inactivityTimeout || 30}
                        onChange={handleCustomizationChange}
                        disabled={isLoading}
                        min="0"
                      />
                    </label>
                    <p className="setting-description">
                      Time of inactivity after which the screensaver is shown and the session is cancelled.
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </main>
      </div>

      <footer className="config-footer">
        <button 
          className="save-btn"
          onClick={handleSaveConfig}
          disabled={isLoading}
        >
          <FaSave /> {isEditMode ? "Update Game" : "Create Game"}
        </button>
      </footer>

      <CropperModal
        isOpen={cropperModalOpen}
        onRequestClose={() => setCropperModalOpen(false)}
        imageSrc={currentImageFile ? URL.createObjectURL(currentImageFile) : null}
        onCrop={handleCrop}
        isLoading={isLoading}
        aspectRatio={aspectRatioValue}
      />
    </div>
  );
};

const getUserProfileLogoUrl = async (userId) => {
  return "";
};

export default GameConfig;