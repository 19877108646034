import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { db, auth } from "../../../firebaseConfig";
import { useAuthState } from "react-firebase-hooks/auth";
import { EmailAuthProvider, reauthenticateWithCredential } from "firebase/auth";
import {
  doc,
  getDoc,
  collection,
  addDoc,
  getDocs,
  query,
  orderBy,
  deleteDoc,
  limit,
} from "firebase/firestore";
import ResetPasswordModal from "../../modals/ResetPasswordModal";
import GameNavbar from "../../common/GameNavbar";
import Sidebar from "../../common/Sidebar";
import Podium from "../../common/Podium";
import styles from "./JigsawPuzzleGame.module.css"; // Importar CSS Module
import LoadingSpinner from "../../LoadingSpinner";

const JigsawPuzzleGame = () => {
  const { eventId, gameId } = useParams();
  const [user] = useAuthState(auth);
  const navigate = useNavigate();

  // -----------------------------------
  // ESTADOS PARA NOMBRE/EMAIL DEL JUGADOR
  // -----------------------------------
  const [playerName, setPlayerName] = useState("");
  const [playerEmail, setPlayerEmail] = useState(""); // <--- NUEVO

  const [currentPlayer, setCurrentPlayer] = useState("");
  const [puzzleImageUrl, setPuzzleImageUrl] = useState("");
  const [pieces, setPieces] = useState([]);
  const [startTime, setStartTime] = useState(null);
  const [timer, setTimer] = useState(0);
  const [gameFinished, setGameFinished] = useState(false);
  const [leaderboard, setLeaderboard] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showResetModal, setShowResetModal] = useState(false);
  const [actionType, setActionType] = useState(null);
  const [showPodium, setShowPodium] = useState(false);
  const [topPlayers, setTopPlayers] = useState([]);
  const [isVerifyingPassword, setIsVerifyingPassword] = useState(false);
  const [customization, setCustomization] = useState({
    navbarColor: "#5d3dfd",
    navbarTitle: "",
    navbarTitleTextColor: "#ffffff",
    logoOption: "noLogo",
    sidebarColor: "#9036d5",
    sidebarTextColor: "#ffffff",
  });
  const [navbarLogo, setNavbarLogo] = useState(null);

  const timerIntervalRef = useRef(null);

  // Parámetros del puzzle
  const gridSize = 3;
  const tileSize = 100;

  // Función para formatear el tiempo de segundos a MM:SS
  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  // Obtener datos del juego al montar el componente
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!user) return;

        // Obtener datos de personalización del juego
        const gameDoc = await getDoc(
          doc(db, `users/${user.uid}/events/${eventId}/games/${gameId}`)
        );
        if (gameDoc.exists()) {
          const gameData = gameDoc.data();
          setCustomization(gameData.customization || {});

          // Obtener el logo de la navbar si está disponible
          if (gameData.customization.navbarLogoUrl) {
            setNavbarLogo(gameData.customization.navbarLogoUrl);
          } else {
            setNavbarLogo(null);
          }

          // Usar la imagen del puzzle de la personalización
          const puzzleImage = gameData.customization?.puzzleImageUrl;
          setPuzzleImageUrl(puzzleImage);

          if (puzzleImage) {
            // Cargar los scores
            const scoresSnapshot = await getDocs(
              query(
                collection(
                  db,
                  `users/${user.uid}/events/${eventId}/games/${gameId}/scores`
                ),
                orderBy("time", "asc")
              )
            );
            const scores = scoresSnapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            setLeaderboard(scores);
          } else {
            // Navegar de vuelta al evento si no se encuentra la imagen del puzzle
            navigate(`/event/${eventId}`);
            return;
          }
        }

        setLoading(false);
      } catch (error) {
        console.error("Error fetching game data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [eventId, gameId, user, navigate]);

  // Manejo del timer
  useEffect(() => {
    if (startTime && !gameFinished) {
      timerIntervalRef.current = setInterval(() => {
        const elapsed = Math.floor((new Date() - startTime) / 1000);
        setTimer(elapsed);
      }, 1000);
    } else {
      clearInterval(timerIntervalRef.current);
    }
    return () => clearInterval(timerIntervalRef.current);
  }, [startTime, gameFinished]);

  // Verificar si el puzzle se resolvió
  useEffect(() => {
    if (pieces.length > 0 && !gameFinished && currentPlayer) {
      if (isPuzzleSolved(pieces)) {
        endGame();
      }
    }
  }, [pieces, gameFinished, currentPlayer]);

  // -----------------------------------
  // INICIAR EL JUEGO
  // -----------------------------------
  const startGame = () => {
    // Validar nombre y email
    if (!playerName.trim()) {
      alert("Please enter your name to start the game.");
      return;
    }
    if (!playerEmail.trim()) {
      alert("Please enter your email to start the game.");
      return;
    }

    setCurrentPlayer(playerName);
    setStartTime(new Date());
    setTimer(0);
    setGameFinished(false);
    initializePuzzle();
  };

  // Inicializar el puzzle
  const initializePuzzle = () => {
    const totalPieces = gridSize * gridSize;
    const tempPieces = [];

    for (let i = 0; i < totalPieces; i++) {
      tempPieces.push({
        id: i,
        correctPosition: i,
        currentPosition: null,
      });
    }

    const shuffledPieces = shuffleArray(tempPieces);
    setPieces(shuffledPieces);
  };

  const shuffleArray = (array) => {
    const newArray = [...array];
    for (let i = newArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
    }
    return newArray;
  };

  // -----------------------------------
  // DRAG AND DROP
  // -----------------------------------
  const handleDragStart = (e, piece) => {
    if (!currentPlayer || gameFinished) return;
    e.dataTransfer.setData("pieceId", piece.id);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, positionIndex) => {
    if (!currentPlayer || gameFinished) return;
    e.preventDefault();
    const pieceId = parseInt(e.dataTransfer.getData("pieceId"), 10);
    const pieceIndex = pieces.findIndex((p) => p.id === pieceId);

    if (pieceIndex === -1) return;

    // Verificar si el slot ya está ocupado
    if (pieces.some((p) => p.currentPosition === positionIndex)) {
      return;
    }

    const newPieces = [...pieces];
    newPieces[pieceIndex] = {
      ...newPieces[pieceIndex],
      currentPosition: positionIndex,
    };
    setPieces(newPieces);
  };

  const handlePieceClick = (piece) => {
    if (gameFinished) return;
    if (piece.currentPosition !== null) {
      const newPieces = [...pieces];
      const pieceIndex = newPieces.findIndex((p) => p.id === piece.id);
      newPieces[pieceIndex] = {
        ...newPieces[pieceIndex],
        currentPosition: null,
      };
      setPieces(newPieces);
    }
  };

  // -----------------------------------
  // LOGICA DE FINALIZAR JUEGO
  // -----------------------------------
  const isPuzzleSolved = (piecesArray) => {
    const solved = piecesArray.every(
      (piece) => piece.currentPosition === piece.correctPosition
    );
    return solved;
  };

  const endGame = async () => {
    if (gameFinished) return;

    clearInterval(timerIntervalRef.current);
    const timeTaken = timer;

    try {
      await addDoc(
        collection(
          db,
          `users/${user.uid}/events/${eventId}/games/${gameId}/scores`
        ),
        {
          name: currentPlayer,
          email: playerEmail, // <--- GUARDAR EL EMAIL
          time: timeTaken,
          timestamp: new Date(),
        }
      );

      // Actualizar leaderboard local
      const scoresSnapshot = await getDocs(
        query(
          collection(
            db,
            `users/${user.uid}/events/${eventId}/games/${gameId}/scores`
          ),
          orderBy("time", "asc")
        )
      );
      const scores = scoresSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setLeaderboard(scores);
    } catch (error) {
      console.error("Error saving score:", error);
      return;
    }

    // Marcar juego como finalizado
    setGameFinished(true);
    // Limpiar jugador actual y email para el siguiente
    setCurrentPlayer("");
    setPlayerName("");
    setPlayerEmail("");
  };

  // -----------------------------------
  // VERIFICACIÓN DE PASSWORD PARA MENÚ
  // -----------------------------------
  const verifyPassword = async (user, password) => {
    if (!user || !user.email) {
      throw new Error("No user is authenticated or user email is missing.");
    }
    const credential = EmailAuthProvider.credential(user.email, password);
    await reauthenticateWithCredential(user, credential);
  };

  const handleVerifyPassword = async (password) => {
    setIsVerifyingPassword(true);
    try {
      await verifyPassword(user, password);
      if (actionType === "resetLeaderboard") {
        await handleResetLeaderboard();
      } else if (actionType === "finishGame") {
        await handleFinishGame();
      } else if (actionType === "redirectToEvent") {
        navigate(`/event/${eventId}`);
      }
      setShowResetModal(false);
    } catch (error) {
      console.error("Error verifying password:", error);
      throw error;
    } finally {
      setIsVerifyingPassword(false);
    }
  };

  // -----------------------------------
  // REINICIAR LEADERBOARD
  // -----------------------------------
  const handleResetLeaderboard = async () => {
    setLoading(true);
    try {
      const scoresRef = collection(
        db,
        `users/${user.uid}/events/${eventId}/games/${gameId}/scores`
      );
      const scoresSnapshot = await getDocs(scoresRef);
      const deletePromises = scoresSnapshot.docs.map((doc) =>
        deleteDoc(doc.ref)
      );
      await Promise.all(deletePromises);
      setLeaderboard([]);

      // Reset del juego
      setGameFinished(false);
      setCurrentPlayer("");
      setPlayerName("");
      setPlayerEmail("");
      setPieces([]);
      setTimer(0);
      setStartTime(null);
    } catch (error) {
      console.error("Error resetting leaderboard:", error);
      alert("Hubo un error al resetear el leaderboard. Inténtalo nuevamente.");
    } finally {
      setLoading(false);
      setShowResetModal(false);
    }
  };

  // -----------------------------------
  // FINALIZAR JUEGO (mostrar Podium)
  // -----------------------------------
  const handleFinishGame = async () => {
    setLoading(true);
    try {
      await fetchTopPlayers();
      await handleResetLeaderboard();
    } catch (error) {
      console.error("Error finishing game:", error);
      alert("Hubo un error al finalizar el juego. Inténtalo nuevamente.");
    } finally {
      setLoading(false);
      setShowResetModal(false);
    }
  };

  const fetchTopPlayers = async () => {
    if (!user) return;
    try {
      const scoresSnapshot = await getDocs(
        query(
          collection(
            db,
            `users/${user.uid}/events/${eventId}/games/${gameId}/scores`
          ),
          orderBy("time", "asc"),
          limit(3)
        )
      );
      const topScores = scoresSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setTopPlayers(topScores);
      setShowPodium(true);
    } catch (error) {
      console.error("Error fetching top players:", error);
      alert("Hubo un error al obtener los mejores jugadores.");
    }
  };

  // -----------------------------------
  // MANEJO DEL MENÚ
  // -----------------------------------
  const handleMenuClick = (action) => {
    if (action === "resetLeaderboard") {
      setActionType("resetLeaderboard");
      setShowResetModal(true);
    } else if (action === "finishGame") {
      setActionType("finishGame");
      setShowResetModal(true);
    } else if (action === "redirectToEvent") {
      setActionType("redirectToEvent");
      setShowResetModal(true);
    }
  };

  const handleLogoClick = () => {
    setActionType("redirectToEvent");
    setShowResetModal(true);
  };

  // -----------------------------------
  // REINICIAR JUEGO DESDE EL PODIUM
  // -----------------------------------
  const handleRestartGame = () => {
    setShowPodium(false);
    setGameFinished(false);
    setCurrentPlayer("");
    setPlayerName("");
    setPlayerEmail("");
    setTimer(0);
    setPieces([]);
    setStartTime(null);
    setLeaderboard([]);
  };

  // Limpieza al desmontar el componente
  useEffect(() => {
    return () => {
      clearInterval(timerIntervalRef.current);
    };
  }, []);

  // Aplicar estilos de personalización
  const gameStyles = {
    "--sidebar-bg": customization.sidebarColor || "#9036d5",
    "--sidebar-text-color": customization.sidebarTextColor || "#ffffff",
    "--navbar-bg": customization.navbarColor || "#5d3dfd",
    "--navbar-title-text-color":
      customization.navbarTitleTextColor || "#ffffff",
  };

  if (loading) {
    return <LoadingSpinner message={"Processing..."} />;
  }

  return (
    <div className={styles.jigsawPuzzleGame} style={gameStyles}>
      <GameNavbar
        customization={customization}
        onMenuClick={handleMenuClick}
        navbarLogo={navbarLogo}
        onLogoClick={handleLogoClick}
      />
      <div className={styles.gameLayout}>
        <Sidebar
          currentPlayer={currentPlayer}
          timer={timer}
          leaderboard={leaderboard}
          formatTime={formatTime}
          customization={customization}
        />
        <div className={styles.gameBoardContainer}>
          {/* Cuadro de inicio para ingresar el nombre y email del jugador */}
          {!currentPlayer && !gameFinished && !showPodium && (
            <div className={styles.startContainer}>
              <input
                type="text"
                value={playerName}
                onChange={(e) => setPlayerName(e.target.value)}
                placeholder="Enter your name"
                className={styles.playerInput}
              />
              <input
                type="email"
                value={playerEmail}
                onChange={(e) => setPlayerEmail(e.target.value)}
                placeholder="Enter your email"
                className={styles.playerInput}
              />
              <button onClick={startGame} className={styles.startButton}>
                Start Game
              </button>
            </div>
          )}

          {/* Tablero de juego */}
          {currentPlayer && !gameFinished && (
            <div className={styles.jigsawGame}>
              <div
                className={styles.puzzleBoard}
                style={{
                  gridTemplateColumns: `repeat(${gridSize}, ${tileSize}px)`,
                  gridTemplateRows: `repeat(${gridSize}, ${tileSize}px)`,
                }}
              >
                {Array.from({ length: gridSize * gridSize }, (_, index) => (
                  <div
                    key={index}
                    className={styles.puzzleSlot}
                    onDragOver={handleDragOver}
                    onDrop={(e) => handleDrop(e, index)}
                  >
                    {pieces
                      .filter((piece) => piece.currentPosition === index)
                      .map((piece) => {
                        const row = Math.floor(piece.id / gridSize);
                        const col = piece.id % gridSize;
                        return (
                          <div
                            key={piece.id}
                            className={`${styles.puzzlePiece} ${styles.draggablePiece}`}
                            draggable
                            onDragStart={(e) => handleDragStart(e, piece)}
                            onClick={() => handlePieceClick(piece)}
                            style={{
                              backgroundImage: `url(${puzzleImageUrl})`,
                              backgroundSize: `${gridSize * tileSize}px ${
                                gridSize * tileSize
                              }px`,
                              backgroundPosition: `${-col * tileSize}px ${
                                -row * tileSize
                              }px`,
                            }}
                          ></div>
                        );
                      })}
                  </div>
                ))}
              </div>
              <div className={styles.piecesContainer}>
                <div className={styles.scrollableContainer}>
                  {pieces
                    .filter((piece) => piece.currentPosition === null)
                    .map((piece) => {
                      const row = Math.floor(piece.id / gridSize);
                      const col = piece.id % gridSize;
                      return (
                        <div
                          key={piece.id}
                          className={`${styles.puzzlePiece} ${styles.draggablePiece}`}
                          draggable
                          onDragStart={(e) => handleDragStart(e, piece)}
                          style={{
                            backgroundImage: `url(${puzzleImageUrl})`,
                            backgroundSize: `${gridSize * tileSize}px ${
                              gridSize * tileSize
                            }px`,
                            backgroundPosition: `${-col * tileSize}px ${
                              -row * tileSize
                            }px`,
                          }}
                        ></div>
                      );
                    })}
                </div>
              </div>
            </div>
          )}

          {/* Pantalla de juego terminado y formulario para siguiente jugador */}
          {gameFinished && !showPodium && (
            <div className={styles.gameFinished}>
              <h2>Congratulations!</h2>
              <p>Your Time: {formatTime(timer)}</p>
              <input
                type="text"
                value={playerName}
                onChange={(e) => setPlayerName(e.target.value)}
                placeholder="Enter the next player's name"
                className={styles.playerInput}
                disabled={loading}
              />
              <input
                type="email"
                value={playerEmail}
                onChange={(e) => setPlayerEmail(e.target.value)}
                placeholder="Enter the next player's email"
                className={styles.playerInput}
                disabled={loading}
              />
              <button
                onClick={startGame}
                className={styles.startButton}
                disabled={loading}
              >
                Start New Game
              </button>
            </div>
          )}
        </div>
      </div>

      {/* Mostrar el Podium si está activo */}
      {showPodium && (
        <Podium
          topPlayers={topPlayers}
          onRestart={handleRestartGame}
          formatTime={formatTime}
        />
      )}

      {/* Modal para password */}
      {showResetModal && (
        <ResetPasswordModal
          onSubmit={handleVerifyPassword}
          onCancel={() => setShowResetModal(false)}
          isLoading={isVerifyingPassword}
          actionName={
            actionType === "resetLeaderboard"
              ? "Reset Leaderboard"
              : actionType === "finishGame"
              ? "Finalize Game"
              : actionType === "redirectToEvent"
              ? "Return to Event"
              : ""
          }
        />
      )}
    </div>
  );
};

export default JigsawPuzzleGame;
