import React from 'react';
import PlayerInfo from './PlayerInfo';
import Leaderboard from './Leaderboard';
import './Sidebar.css';

const Sidebar = ({
  currentPlayer,
  timer,
  leaderboard,
  formatTime,
  customization,
  isActive,
  onCancelSession,
}) => {
  const sidebarStyle = {
    '--sidebar-bg': customization.sidebarColor || 'rgba(25, 25, 35, 0.98)',
    '--sidebar-text': customization.sidebarTextColor || 'rgba(255, 255, 255, 0.9)',
    '--accent-color': customization.accentColor || 'rgba(255, 255, 255, 0.4)',
    '--border-color': 'rgba(255, 255, 255, 0.08)',
    '--divider-color': 'rgba(255, 255, 255, 0.05)'
  };

  return (
    <div className={`sidebar ${isActive ? 'active' : ''}`} style={sidebarStyle}>
      <div className="sidebar-content">
        <PlayerInfo
          currentPlayer={currentPlayer}
          timer={timer}
          formatTime={formatTime}
          textColor={customization.sidebarTextColor}
          dividerColor={customization.accentColor || customization.sidebarTextColor}
        />
        
        {currentPlayer && (
          <button 
            onClick={onCancelSession} 
            className="cancel-session-button"
            style={{
              '--button-text': customization.sidebarTextColor || 'rgba(255, 255, 255, 0.9)'
            }}
          >
            <span className="icon">✕</span>
            <span>End Session</span>
          </button>
        )}
        
        <Leaderboard 
          leaderboard={leaderboard} 
          formatTime={formatTime}
          textColor={customization.sidebarTextColor}
          accentColor={customization.accentColor}
        />
      </div>
    </div>
  );
};

export default Sidebar;