// src/components/GameConfig/CropperModal.js
import React, { useState } from 'react';
import Cropper from 'react-cropper';
import Modal from 'react-modal';
import 'cropperjs/dist/cropper.css';
import '../../App.css'

const CropperModal = ({
  isOpen,
  onRequestClose,
  imageSrc,
  onCrop,      
  isLoading,
  aspectRatio, // Prop para definir la relación de aspecto
}) => {
  const [cropperInstance, setCropperInstance] = useState(null);

  const handleCrop = () => {
    if (cropperInstance) {
      cropperInstance.getCroppedCanvas({
        width: 1920,
        height: aspectRatio ? Math.round(1920 / aspectRatio) : 1920, // calcula el alto según el aspectRatio; si no se pasa, se asume 1 (cuadrado)
        imageSmoothingQuality: 'high',
      }).toBlob(
        (blob) => {
          if (!blob) {
            console.error("No se pudo generar el blob de la imagen recortada.");
            return;
          }
          onCrop(blob);
        },
        'image/webp',
        0.5
      );
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Recortar Imagen"
      className="Modal ReactModal__Content"
      overlayClassName="Overlay ReactModal__Overlay"
    >
      <h2>Recortar Imagen</h2>
      {imageSrc && (
        <Cropper
          src={imageSrc}
          style={{ height: 400, width: '100%' }}
          initialAspectRatio={aspectRatio || 1}
          aspectRatio={aspectRatio || 1}
          guides={false}
          viewMode={1}
          minCropBoxHeight={10}
          minCropBoxWidth={10}
          background={false}
          responsive={true}
          autoCropArea={1}
          checkOrientation={false}
          onInitialized={(instance) => setCropperInstance(instance)}
        />
      )}
      <button className="btn-save" onClick={handleCrop} disabled={isLoading}>
        Recortar & Guardar
      </button>
    </Modal>
  );
};

export default CropperModal;
