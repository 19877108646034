import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { FaTimes, FaTrash } from "react-icons/fa";
import "./ScoresModal.css";

const ScoresModal = ({
  isOpen,
  onClose,
  gameName,
  scores,
  onDeleteScore,
  onDeleteAllScores,
}) => {
  if (!isOpen) return null;

  return (
    <AnimatePresence>
      <motion.div
        className="scores-modal-backdrop"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <motion.div
          className="scores-modal-content"
          initial={{ scale: 0.8 }}
          animate={{ scale: 1 }}
          exit={{ scale: 0.8 }}
          transition={{ duration: 0.2 }}
        >
          <div className="scores-modal-header">
            <h2>{gameName} - Winners</h2>
            <button className="close-button" onClick={onClose}>
              <FaTimes />
            </button>
          </div>
          <div className="scores-modal-body">
            {scores.length === 0 ? (
              <p>No winners found for this game.</p>
            ) : (
              <table className="scores-table">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Time (sec)</th>
                    <th>Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {scores.map((score) => (
                    <tr key={score.id}>
                      <td>{score.name || "N/A"}</td>
                      <td>{score.email || "N/A"}</td>
                      <td>{score.time || 0}</td>
                      <td>
                        {score.timestamp
                          ? new Date(score.timestamp.toDate()).toLocaleString()
                          : ""}
                      </td>
                      <td>
                        <button
                          className="btn-delete-score"
                          onClick={() => onDeleteScore(score.id)}
                        >
                          <FaTrash /> Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
          {scores.length > 0 && (
            <div className="scores-modal-footer">
              <button className="btn-delete-all" onClick={onDeleteAllScores}>
                <FaTrash /> Delete All
              </button>
            </div>
          )}
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default ScoresModal;
