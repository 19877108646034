// src/hooks/usePreloadImages.js
import { useEffect, useState } from "react";

export const usePreloadImages = (urls = []) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!urls.length) {
      setLoaded(true);
      return;
    }
    let loadedCount = 0;
    urls.forEach((url) => {
      const img = new Image();
      img.src = url;
      img.onload = () => {
        loadedCount++;
        if (loadedCount === urls.length) {
          setLoaded(true);
        }
      };
      img.onerror = () => {
        loadedCount++;
        if (loadedCount === urls.length) {
          setLoaded(true);
        }
      };
    });
  }, [urls]);

  return loaded;
};
