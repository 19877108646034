import React, { useEffect, useRef } from "react";
import "./Leaderboard.css";

const Leaderboard = ({ leaderboard, formatTime, accentColor }) => {
  const listRef = useRef(null);
  
  // Efecto de scroll animado
  useEffect(() => {
    if (listRef.current && leaderboard.length > 0) {
      listRef.current.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  }, [leaderboard]);

  const topTen = leaderboard.slice(0, 10);

  return (
    <div className="leaderboard-container">
      <div className="leaderboard-header">
        <h2 className="leaderboard-title">
          <span className="title-text">Leaderboard</span>
          <span className="title-underline" style={{ backgroundColor: accentColor }}></span>
        </h2>
      </div>
      <div className="leaderboard-scroll" ref={listRef}>
        <ul className="leaderboard-list">
          {topTen.map((entry, index) => (
            <li key={index} className="leaderboard-item">
              <div className="item-rank" style={{ color: accentColor }}>
                {index + 1}
              </div>
              <div className="item-content">
                <div className="item-name">{entry.name}</div>
                <div className="item-time">{formatTime(entry.time || entry.score)}</div>
              </div>
              <div className="item-medal">
                {index === 0 && '🥇'}
                {index === 1 && '🥈'}
                {index === 2 && '🥉'}
              </div>
            </li>
          ))}
          {topTen.length === 0 && (
            <li className="leaderboard-empty">
              <div className="empty-icon">🏆</div>
              <div>No scores yet</div>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default Leaderboard;