// src/components/GameConfig/GameConfigHeader.js

import React from "react";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const GameConfigHeader = ({ isEditMode, isLoading }) => {
  const navigate = useNavigate();

  return (
    <div className="header">
      <button
        className="btn-back"
        onClick={() => navigate(-1)}
        disabled={isLoading}
      >
        <FaArrowLeft /> Back
      </button>
      <h1>{isEditMode ? "Edit Game" : "Create New Game"}</h1>
    </div>
  );
};

export default GameConfigHeader;
