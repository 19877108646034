// src/components/GameConfig/MemoryMatchFields.js
import React from 'react';
import { FaTrash, FaUpload } from 'react-icons/fa';
import './MemoryMatchFields.css';

const MemoryMatchFields = ({
  images = [],
  existingImages = [],
  onAddImages,
  onDeleteImage,
  backImage,
  existingBackImage,
  onAddBackImage,
  onDeleteBackImage,
  screensaverImage,
  existingScreensaverImage,
  onAddScreensaverImage,
  onDeleteScreensaverImage,
  customization = {},
  handleCustomizationChange,
  isLoading = false,
}) => {
  return (
    <div className="memory-match-fields">
      {/* Card Images Section */}
      <div className="section">
        <h3 className="section-title">
          <span>Card Images</span>
          <span className="section-subtitle">(Upload exactly 12 images)</span>
        </h3>
        
        <div className="image-upload-container">
          <label className="upload-button">
            <FaUpload /> Upload Images
            <input
              type="file"
              multiple
              accept="image/*"
              onChange={onAddImages}
              disabled={isLoading}
              className="hidden-input"
            />
          </label>
        </div>

        {(images.length > 0 || existingImages.length > 0) && (
          <div className="image-grid">
            {existingImages.map((url, index) => (
              <div key={`existing-${index}`} className="image-card">
                <img src={url} alt={`Card ${index + 1}`} />
                <button
                  className="delete-button"
                  onClick={() => onDeleteImage(index, 'existing')}
                  disabled={isLoading}
                >
                  <FaTrash />
                </button>
              </div>
            ))}
            
            {images.map((img, index) => (
              <div key={`new-${index}`} className="image-card">
                <img src={img.url} alt={`New card ${index + 1}`} />
                <button
                  className="delete-button"
                  onClick={() => onDeleteImage(index, 'new')}
                  disabled={isLoading}
                >
                  <FaTrash />
                </button>
              </div>
            ))}
          </div>
        )}
      </div>

      {/* // Para Card Back Image Section */}
<div className="single-image-upload">
  {(backImage || existingBackImage) && (
    <div className="image-preview-container">
      <img 
        src={backImage ? URL.createObjectURL(backImage) : existingBackImage} 
        alt="Card back" 
      />
      <div className="image-actions">
        <label className="replace-button">
          <FaUpload /> Replace
          <input
            type="file"
            accept="image/*"
            onChange={onAddBackImage}
            disabled={isLoading}
            className="hidden-input"
          />
        </label>
        <button
          className="delete-button"
          onClick={onDeleteBackImage}
          disabled={isLoading}
        >
          <FaTrash /> Remove
        </button>
      </div>
    </div>
  )}
  {!(backImage || existingBackImage) && (
    <label className="upload-button">
      <FaUpload /> Upload Back Image
      <input
        type="file"
        accept="image/*"
        onChange={onAddBackImage}
        disabled={isLoading}
        className="hidden-input"
      />
    </label>
  )}
</div>

{/* // Para Screensaver Image Section */}
<div className="single-image-upload">
  {(screensaverImage || existingScreensaverImage) && (
    <div className="image-preview-container">
      <img 
        src={screensaverImage ? URL.createObjectURL(screensaverImage) : existingScreensaverImage} 
        alt="Screensaver" 
      />
      <div className="image-actions">
        <label className="replace-button">
          <FaUpload /> Replace
          <input
            type="file"
            accept="image/*"
            onChange={onAddScreensaverImage}
            disabled={isLoading}
            className="hidden-input"
          />
        </label>
        <button
          className="delete-button"
          onClick={onDeleteScreensaverImage}
          disabled={isLoading}
        >
          <FaTrash /> Remove
        </button>
      </div>
    </div>
  )}
  {!(screensaverImage || existingScreensaverImage) && (
    <label className="upload-button">
      <FaUpload /> Upload Screensaver
      <input
        type="file"
        accept="image/*"
        onChange={onAddScreensaverImage}
        disabled={isLoading}
        className="hidden-input"
      />
    </label>
  )}
</div>

      {/* Game Settings Section */}
      {/* <div className="section">
        <h3 className="section-title">Game Settings</h3>
        
        <div className="setting-group">
          <label>
            Preview Time (seconds)
            <input
              type="number"
              name="memoryPreviewTime"
              value={customization.memoryPreviewTime || 0}
              onChange={handleCustomizationChange}
              disabled={isLoading}
              min="0"
              className="setting-input"
            />
          </label>
          <p className="setting-description">
            Time cards remain visible at game start
          </p>
        </div>

      
      </div> */}
    </div>
  );
};

export default MemoryMatchFields;