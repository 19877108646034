// src/components/common/Podium/Podium.js
import React, { useState, useEffect } from "react";
import { FaTrophy, FaMedal, FaStar } from "react-icons/fa";
import "./Podium.css";

const Podium = ({ topPlayers, onRestart, formatTime }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
    return () => setIsVisible(false);
  }, []);

  return (
    <div className={`podium-overlay ${isVisible ? "visible" : ""}`}>
      <div className="podium-container">
        <h2>Congratulations to the Winners!</h2>
        
        {/* Efecto de partículas optimizado */}
        <div className="particles">
          {[...Array(30)].map((_, i) => (
            <div 
              key={i}
              className="particle"
              style={{
                left: `${Math.random() * 100}%`,
                top: `${Math.random() * 100}%`,
                animationDelay: `${Math.random() * 2}s`,
                background: `hsl(${Math.random() * 360}, 100%, 70%)`
              }}
            />
          ))}
        </div>

        <div className="podium">
          {topPlayers.map((player, index) => (
            <div
              key={player.id || index}
              className={`podium-place podium-${index + 1}`}
            >
              <div className="podium-rank">
                {index === 0 && <FaTrophy className="trophy-icon" />}
                {index === 1 && <FaMedal className="medal-icon" />}
                {index === 2 && <FaStar className="star-icon" />}
                <span className="rank-number">{index + 1}</span>
              </div>
              <div className="podium-name">{player.name}</div>
              <div className="podium-time">{formatTime(player.time)}</div>
              <div className="podium-player-info">
                {player.email && <div className="player-email">{player.email}</div>}
              </div>
            </div>
          ))}
        </div>

        <button onClick={onRestart} className="restart-button">
          Restart Game
        </button>
      </div>
    </div>
  );
};

export default Podium;